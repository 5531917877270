import PATHS from 'paths'

export const CROSS_SELL_DATA_PROBATE = {
  title: 'We can also help with probate',
  description:
    'Probate is a legal document which gives you the authority to handle the estate of the person who has died. Their estate could include money, property and personal possessions. We can help check if you need probate, and if you do, give you a fixed-price quote for the different ways we can support you.',
  image: {
    path: 'illustrations/help-with-probate',
  },
}

export const CROSS_SELL_DATA_PLANS = {
  title: 'The Farewill funeral plan',
  description:
    'A funeral plan is a way of arranging and paying for a funeral in advance. It helps protect those closest to you from the stress of deciding what kind of funeral you would have wanted and the stress and cost of arranging it.',
  image: {
    path: 'illustrations/cropped/blob-cremation-urn',
    width: 300,
    widthFromM: 320,
    widthFromL: 380,
    widthFromXL: 600,
  },
  cta: {
    text: 'More about our funeral plan',
    to: PATHS.CREMATION.FUNERAL_PLANS,
  },
}
