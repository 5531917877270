import {
  ROOT_URL,
  TRUSTPILOT_RATING_OUT_OF_5,
  TRUSTPILOT_REVIEWS_TOTAL,
  CREMATION_SIMPLE_FEE,
} from 'config'
import { formatPriceInPounds } from 'lib/formatting/pricing'
import PATHS from 'lib/navigation/paths'

import FAREWILL_JSONLD from './farewill'

export default {
  '@context': 'http://schema.org/',
  '@type': 'Product',
  name: 'Direct Cremation',
  brand: FAREWILL_JSONLD,
  image: `${ROOT_URL}/logos/share.jpg`,
  description: `Direct Cremation is a funeral without a service for someone who's died. We can help arrange a simple cremation from ${formatPriceInPounds(
    CREMATION_SIMPLE_FEE
  )}.`,
  aggregateRating: {
    '@type': 'AggregateRating',
    ratingValue: TRUSTPILOT_RATING_OUT_OF_5,
    reviewCount: TRUSTPILOT_REVIEWS_TOTAL,
    bestRating: 5,
    worstRating: 1,
  },
  offers: {
    '@type': 'Offer',
    priceCurrency: 'GBP',
    price: CREMATION_SIMPLE_FEE,
    priceValidUntil: '2020-11-28',
    url: `${ROOT_URL}${PATHS.CREMATION.DIRECT}`,
    seller: FAREWILL_JSONLD,
  },
}
