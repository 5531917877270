import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { Grid, Wrapper, Image, H, P } from '@farewill/ui'
import { Link } from '@reach/router'

import PATHS from 'paths'

const StyledGrid = styled(Grid)`
  align-items: center;
  column-gap: 0;
`

const StyledImageWrapper = styled(Grid.Item)`
  order: 2;
  ${screenMin.m`
    order: 1;
  `}
`

const StyledContentWrapper = styled(Grid.Item)`
  order: 1;
  ${screenMin.m`
    order: 2;
  `}
`

const SimpleFunerals = (): ReactElement => (
  <Wrapper>
    <Wrapper container>
      <StyledGrid>
        <StyledImageWrapper
          startColumnFromL={2}
          spanFromM={6}
          spanFromL={5}
          centered
        >
          <Image
            path="illustrations/fair-on-price"
            width={200}
            widthFromM={250}
            widthFromL={300}
            widthFromXL={360}
          />
        </StyledImageWrapper>
        <StyledContentWrapper
          spanFromL={5}
          spanFromM={6}
          padding={[0, 'M', 0, 0]}
        >
          <H size="L" margin={[0, 0, 'S']} decorative>
            Simpler and more affordable
          </H>
          <P>
            Whether you’re arranging a funeral now, or setting up a{' '}
            <Link to={PATHS.CREMATION.FUNERAL_PLANS}>funeral plan</Link>, save
            thousands by cutting out the extra fuss, like a hearse, an organist
            or procession of funeral cars.
          </P>
          <P>
            Our direct cremations are 80% cheaper than the average traditional
            funeral, and give families and friends the freedom to say a more
            personal goodbye.
          </P>
        </StyledContentWrapper>
      </StyledGrid>
    </Wrapper>
  </Wrapper>
)

export default SimpleFunerals
