import { CREMATION_DELIVERY_FEE } from 'config'
import { FORMATTED_DIRECT_CREMATION_PRICE } from 'views/cremation/shared/constants'

export const data = {
  title: `Everything you need, from ${FORMATTED_DIRECT_CREMATION_PRICE}`,
  subtitle:
    'A direct cremation is a cremation you don’t attend. By separating the cremation from the service, you’re free to say goodbye your way.',
  cards: [
    {
      title: 'We make the arrangements',
      subtitle:
        'With Farewill you’ll speak with the same person throughout – a friendly funeral specialist who’ll complete the paperwork and offer their support, ideas, or simply their ear, anytime.',
      imagePath: 'illustrations/funeral-coordinator',
    },
    {
      title: 'We carry out the cremation',
      subtitle:
        'We’ll bring the person who has died into our care. We’ll place them in an oak veneer coffin and transport them to a crematorium, where we’ll carry out a cremation you don’t attend.',
      imagePath: 'illustrations/chapel-of-rest',
    },
    {
      title: 'We hand-deliver the ashes',
      subtitle: `You can collect the ashes, we can scatter them for you, or we can hand-deliver the ashes anywhere in mainland UK, for £${CREMATION_DELIVERY_FEE}. Create the send-off you want, when you’re ready.`,
      imagePath: 'illustrations/direct-cremation',
    },
  ],
}
