import React, { ReactElement } from 'react'
import { Button, Grid, H, P, Wrapper, Image } from '@farewill/ui'
import { screenMax } from '@farewill/ui/helpers/responsive'
import { Link } from 'gatsby'
import styled from 'styled-components'

import PATHS from 'paths'
import { FLOW_FUNERAL_URL } from 'config'
import { data } from './data'

const StyledGrid = styled(Grid)`
  align-items: center;
`

const StyledImage = styled(Image)`
  ${screenMax.s`
    width: 100%;
  `}
`

const RememberYourWay = (): ReactElement => (
  <Wrapper container>
    <Wrapper
      margin={['L', 'auto']}
      marginFromM={['XXL', 'auto', 0]}
      padding={['XS', 0]}
      paddingFromM={['XL', 0]}
      maxWidthInColumns={8}
      centeredFromM
    >
      <H tag="h2" size="L" decorative>
        Remember them your way
      </H>

      <P size="L">
        We specialise in direct cremations because they give you the freedom to
        say goodbye your way.
      </P>
    </Wrapper>
    {data.map((item) => (
      <StyledGrid
        key={item.imagePath}
        margin={[0, 0, 'L']}
        marginFromM={['XL', 0, 'XXL']}
        paddingFromM={['S', 0]}
      >
        <Grid.Item
          spanFromM={6}
          startColumnFromM={item.imagePosition === 'left' ? 1 : 7}
          spanFromL={5}
          startColumnFromL={item.imagePosition === 'left' ? 2 : 7}
          centered
          centeredFromXL={false}
        >
          <StyledImage
            path={item.imagePath}
            width={250}
            widthFromM={300}
            widthFromL={item.imagePosition === 'left' ? 350 : 440}
            widthFromXL={item.imagePosition === 'left' ? 428 : 540}
          />
        </Grid.Item>
        <Grid.Item
          spanFromM={6}
          spanFromL={5}
          startColumnFromM={item.imagePosition === 'left' ? 7 : 1}
          startColumnFromL={item.imagePosition === 'left' ? 7 : 2}
        >
          <H tag="h3" size="S" margin={[0, 0, 'S']}>
            {item.title}
          </H>
          {item.description}
        </Grid.Item>
      </StyledGrid>
    ))}
    <Wrapper centeredFromL margin={['XL', 0, 0]}>
      <Button.Primary tag={Link} to={FLOW_FUNERAL_URL} wide>
        Get a quote in 5 minutes
      </Button.Primary>
      <P size="S" margin={['S', 0, 0]}>
        Or find out about our{' '}
        <Link to={PATHS.CREMATION.FUNERAL_PLANS}>funeral plan</Link>
      </P>
    </Wrapper>
  </Wrapper>
)

export default RememberYourWay
