import React from 'react'
import styled from 'styled-components'
import { Wrapper, Image } from '@farewill/ui'
import { screenMax } from '@farewill/ui/helpers/responsive'
import { COLOR } from '@farewill/ui/tokens'
import WrapperWithEdge from 'components/WrapperWithEdge'
import Cards from 'views/cremation/components/Cards'
import { data } from './data'

const StyledWrapperWithEdge = styled(WrapperWithEdge)`
  position: relative;
`

const StyledWrapper = styled(Wrapper)`
  position: relative;
  overflow: hidden;
`

const StyledTextureOne = styled(Image)`
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 60px;

  ${screenMax.s`
    top: 30%;
    width: 80%;
    right: -20%;
    object-fit: contain;
  `}

  ${screenMax.m`
    width: 80%;
    right: -30%;
    object-fit: contain;
  `}
`

const StyledTextureTwo = styled(Image)`
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;

  ${screenMax.m`
    top: 0;
    object-fit: contain;
    object-position: top;
  `}
`

const StyledTextureThree = styled(Image)`
  pointer-events: none;
  position: absolute;
  left: 0;
  bottom: 5%;

  ${screenMax.m`
    bottom: 10%;
    width: 80%;
    object-fit: contain;
    object-position: top;
  `}
`

const ProductSummary = () => (
  <StyledWrapperWithEdge direction="down" edgeBackgroundColor={COLOR.WHITE}>
    <StyledWrapper>
      <StyledTextureTwo
        path="textures/detail-4-dark"
        width={355}
        height={200}
      />
      <StyledTextureOne
        path="textures/yellow-detailed-dots"
        width={266}
        height={412}
      />
      <StyledTextureThree
        path="textures/sketchy-swoop-yellow-03_k3g7yy"
        width={266}
        height={412}
      />
      <Cards title={data.title} cards={data.cards} />
    </StyledWrapper>
  </StyledWrapperWithEdge>
)

export default ProductSummary
