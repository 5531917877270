import React, { ReactElement } from 'react'
import { COLOR } from '@farewill/ui/tokens'

import DefaultLayout from 'layouts/DefaultLayout'
import { PRODUCTS } from 'lib/products/constants'
import CREMATION_JSONLD from 'lib/json-ld/cremation'
import {
  funeralDirectBreadCrumb,
  getStructuredBreadCrumbs,
} from 'lib/json-ld/getStructuredBreadCrumbs'
import JSONLD from 'components/JSONLD'

import { FORMATTED_DIRECT_CREMATION_PRICE } from 'views/cremation/shared/constants'

import Content from 'views/cremation/public/Direct/Content'
import PATHS from 'paths'

const pageTitle = `Direct cremation from ${FORMATTED_DIRECT_CREMATION_PRICE} - Arrange today with Farewill`
const pageDescription =
  'Arrange a direct cremation without a funeral service for someone who’s died. We’ll take care of the cremation and hand deliver your loved one’s ashes.'

const Direct = (): ReactElement => (
  <>
    <JSONLD
      data={[
        CREMATION_JSONLD,
        getStructuredBreadCrumbs({
          itemListElements: [funeralDirectBreadCrumb],
        }),
      ]}
    />
    <DefaultLayout
      title={pageTitle}
      description={pageDescription}
      headerColor={COLOR.WHITE}
      product={PRODUCTS.FUNERAL}
      showLiveChatFront
      headerType="compact"
      headerFarewillLogoLink={PATHS.CREMATION.FUNERAL_OVERVIEW}
      footerType="compact"
      footerShowStandardisedPriceList
      footerShowFuneralPlansLegalInformation
      meta={[
        {
          name: 'robots',
          content: 'noindex,follow',
        },
      ]}
    >
      <Content />
    </DefaultLayout>
  </>
)

export default Direct
