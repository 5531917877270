import React from 'react'
import { Divider, Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import styled from 'styled-components'

import { graphql, useStaticQuery } from 'gatsby'

import CrossSell from 'components/CrossSell'
import FrequentlyAskedQuestions from 'components/FrequentlyAskedQuestions'
import TrustpilotCarousel from 'components/TrustpilotWidget/TrustpilotCarousel'
import { AWARD_STRAPLINE } from 'lib/awards/constants'
import { PRODUCTS } from 'lib/products/constants'

import { FORMATTED_DIRECT_CREMATION_PRICE } from 'views/cremation/shared/constants'
import Hero from 'views/cremation/components/Hero'
import SliceLowCost from 'views/cremation/components/LowCostSection'
import Serving from 'views/cremation/components/Serving'
import SimpleFunerals from 'views/cremation/public/Direct/components/SimpleFunerals'
import ProductSummary from 'views/cremation/public/Direct/components/ProductSummary'
import RememberYourWay from 'views/cremation/public/Direct/components/RememberYourWay'

import { CROSS_SELL_DATA_PLANS } from './data'

const StyledTrustpilotCarousel = styled(TrustpilotCarousel)`
  padding-top: 0;
  padding-bottom: ${GTR.L};
`

const Content = (): React.ReactElement => {
  const { faqSection } = useStaticQuery<{
    faqSection: GatsbyTypes.ContentfulFaqSection
  }>(graphql`
    query Direct {
      faqSection: contentfulFaqSection(entryTitle: { eq: "Direct cremation" }) {
        ...FaqSectionFields
      }
    }
  `)

  return (
    <Wrapper background={COLOR.WHITE}>
      <Hero
        title="Arrange a simple, respectful direct cremation"
        maxTitleWidthInColumns={7}
        subtitle={`If someone has died, our award-winning team will help you arrange a cremation without a service, from just ${FORMATTED_DIRECT_CREMATION_PRICE}.`}
        ctaText="Get a quote in 5 minutes"
      />
      <ProductSummary />
      <SimpleFunerals />
      <SliceLowCost
        variant="light"
        subtitle="We believe everyone deserves a send-off as unique as they are, without compromising on cost, or quality. Our award-winning funeral directors are here 7 days a week to support you through the process."
        subtitle2={AWARD_STRAPLINE.GOOD_FUNERAL_AWARDS}
      />
      <RememberYourWay />
      <Divider container />

      <Wrapper background={COLOR.WHITE}>
        <Serving imagePosition="left" />
        <StyledTrustpilotCarousel />
        <CrossSell
          title={CROSS_SELL_DATA_PLANS.title}
          description={CROSS_SELL_DATA_PLANS.description}
          image={CROSS_SELL_DATA_PLANS.image}
          cta={CROSS_SELL_DATA_PLANS.cta}
        />
        <Wrapper container containerPaddingTop={GTR.M}>
          <FrequentlyAskedQuestions
            title={faqSection.title}
            questions={faqSection.questions}
            selectedProduct={PRODUCTS.FUNERAL}
            showCallUsTodayCta
            ctaSubtitle="Our specialists can talk through your plans and answer your questions."
          />
        </Wrapper>
      </Wrapper>
    </Wrapper>
  )
}

export default Content
