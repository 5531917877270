import React, { ReactElement } from 'react'
import styled from 'styled-components'

import { Button, Grid, H, Image, P, Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { screenMax } from '@farewill/ui/helpers/responsive'

import ExternalLink from 'components/ExternalLink'

const StyledWrapper = styled(Wrapper)`
  border-radius: ${GTR.S};
`
const StyledGrid = styled(Grid)`
  align-items: center;
  column-gap: 0;
`

const StyledImage = styled(Image)`
  max-width: 490px;

  ${screenMax.s`
    width: 100%;
  `}
`

const StyledButton = styled(Button.BorderedDark)`
  ${screenMax.s`
    width: 100%;
  `}
`

export type CrossSellProps = {
  title: string
  description: string
  image: {
    path: string
    width?: number
    widthFromM?: number
    widthFromL?: number
    widthFromXL?: number
  }
  cta?: { text: string; to: string }
}

const CrossSell = ({
  title,
  description,
  image,
  cta,
}: CrossSellProps): ReactElement => (
  <Wrapper container containerPaddingTop="XS">
    <StyledWrapper
      background={COLOR.ACCENT.PRIMARY_10}
      padding={['XL', 'M']}
      paddingFromM={['XL', 'L']}
      paddingFromXL={['XXL', 'XL']}
    >
      <StyledGrid>
        <Grid.Item spanFromM={4} spanFromL={5} centered>
          <StyledImage
            path={image.path}
            width={image?.width || 200}
            widthFromM={image?.widthFromM || 250}
            widthFromL={image?.widthFromL || 300}
            widthFromXL={image?.widthFromXL || 360}
            stretch
          />
        </Grid.Item>
        <Grid.Item startColumnFromM={6} startColumnFromL={7} spanFromL={6}>
          <H size="M" margin={[0, 0, 'S']} decorative>
            {title}
          </H>
          <P margin={[0, 0, 'M']}>{description}</P>
          {cta && (
            <StyledButton tag={ExternalLink} href={cta.to}>
              {cta.text}
            </StyledButton>
          )}
        </Grid.Item>
      </StyledGrid>
    </StyledWrapper>
  </Wrapper>
)

export default CrossSell
