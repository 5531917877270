import React from 'react'
import { P } from '@farewill/ui'

export const data = [
  {
    title: 'A send-off that feels just right',
    description: (
      <>
        <P>
          There are lots of ways to remember a loved one. Instead of a service
          at the crematorium, some people choose a quiet day of reflection, or a
          gathering of friends.
        </P>
        <P>
          Others get together to scatter the ashes, or plan a memorial or
          celebration of life party. Whatever you decide, remember them your
          way.
        </P>
      </>
    ),
    imagePath: 'illustrations/raise-glass-peace',
    imagePosition: 'left',
  },
  {
    title: 'Add a personal touch',
    description: (
      <>
        <P>
          Your funeral specialist can talk through ways to add a personal touch,
          from scattering urns for releasing the ashes at sea, to ceramic
          display urns for keeping them close.
        </P>
        <P>
          They can also tell you more about our memorial trees. These come in
          all shapes and sizes and include an engraved brass plaque, and
          everything you need to help the tree thrive.
        </P>
      </>
    ),
    imagePath: 'com-assets/direct-cremation/urns-trees',
    imagePosition: 'right',
  },
]
