import React from 'react'
import styled from 'styled-components'
import { H, P, Wrapper, Image, Grid } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

const StyledWrapper = styled(Wrapper)`
  position: relative;
  overflow: hidden;
`

const StyledCard = styled(Grid.Item)`
  position: relative;
  border-radius: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export type Card = {
  title: string
  subtitle: string
  imagePath: string
}

export type CardsProps = {
  title: string
  subtitle?: string
  cards: Card[]
}

const Cards = ({ title, subtitle, cards }: CardsProps) => (
  <>
    <StyledWrapper container maxWidthInColumns={8}>
      <H tag="h2" size="L" decorative centeredFromL margin={[0]}>
        {title}
      </H>
      <P size="L" color={COLOR.BLACK} centeredFromL margin={['M', 0, 0]}>
        {subtitle}
      </P>
    </StyledWrapper>
    <Grid containerPaddingTop={0} container gapFromL={GTR.M} gapFromXL={GTR.XL}>
      {cards.map((item) => (
        <Grid.Item spanFromL={4} key={`card-${item.imagePath}`}>
          <StyledCard background={COLOR.WHITE} padding={['L', 'M']}>
            <Wrapper centered margin={[0, 0, 'M']}>
              <Image
                path={item.imagePath}
                width={180}
                height={180}
                widthFromM={240}
                heightFromM={240}
              />
            </Wrapper>
            <div>
              <H tag="h3" size="XS" centered>
                {item.title}
              </H>
              <P centered paddingFromM={[0]}>
                {item.subtitle}
              </P>
            </div>
          </StyledCard>
        </Grid.Item>
      ))}
    </Grid>
  </>
)

export default Cards
